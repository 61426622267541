function KnowledgeBaseIcon() {
    return (
        <svg width="124" height="112" viewBox="0 0 124 112" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_44_95)">
                <g opacity="0.28">
                    <path
                        d="M110.652 29.4793C122.319 49.6332 134.918 71.5001 107.857 85.7749C80.7972 100.05 24.3236 101.331 12.6541 81.1741C0.984575 61.0177 13.464 33.0972 40.5243 18.8326C67.5846 4.56794 98.9882 9.32282 110.652 29.4793Z"
                        fill="url(#paint0_linear_44_95)"/>
                </g>
                <g opacity="0.35">
                    <path
                        d="M63.8685 1.56655C88.2345 8.6534 84.791 36.8593 75.5751 65.7246C66.3591 94.59 54.8617 113.188 30.4957 106.096C6.12979 99.0038 -6.15376 69.8605 3.06221 40.9952C12.2782 12.1299 39.5026 -5.52282 63.8685 1.56655Z"
                        fill="url(#paint1_linear_44_95)"/>
                </g>
                <g opacity="0.7">
                    <path
                        d="M103.911 59.8479C102.588 82.5662 56.1269 62.2279 56.86 104.762C57.2888 129.562 9.81146 84.6531 9.81146 59.8479C9.81111 53.9499 11.0278 48.1095 13.3921 42.6604C15.7563 37.2112 19.2218 32.2599 23.5907 28.0893C27.9596 23.9186 33.1463 20.6103 38.8547 18.3531C44.5631 16.0959 50.6813 14.9342 56.86 14.9342C82.8431 14.9342 105.34 35.0806 103.911 59.8479Z"
                        fill="url(#paint2_linear_44_95)"/>
                </g>
                <path
                    d="M67.4258 45.8182H67.5185C67.6575 45.8182 67.7952 45.8444 67.9236 45.8952C68.0521 45.946 68.1688 46.0204 68.2671 46.1142C68.3654 46.2081 68.4434 46.3195 68.4966 46.4421C68.5498 46.5647 68.5772 46.6961 68.5772 46.8288V55.1916C68.5772 55.4596 68.4656 55.7167 68.2671 55.9062C68.0685 56.0957 67.7992 56.2022 67.5185 56.2022H67.4258C67.145 56.2022 66.8758 56.0957 66.6772 55.9062C66.4787 55.7167 66.3671 55.4596 66.3671 55.1916V46.8288C66.3671 46.5608 66.4787 46.3038 66.6772 46.1142C66.8758 45.9247 67.145 45.8182 67.4258 45.8182Z"
                    fill="white"/>
                <path
                    d="M60.2717 56.0429C60.1346 56.0432 59.9988 56.0177 59.8722 55.9678C59.7455 55.9179 59.6304 55.8446 59.5335 55.7521C59.4366 55.6596 59.3598 55.5497 59.3075 55.4288C59.2552 55.3079 59.2285 55.1783 59.2288 55.0475C59.2288 51.4017 56.7674 47.6877 54.3535 47.6877C52.0138 47.6877 49.7164 51.3335 49.7164 55.0475C49.7164 55.3115 49.6066 55.5647 49.411 55.7513C49.2154 55.938 48.9502 56.0429 48.6736 56.0429C48.397 56.0429 48.1318 55.938 47.9362 55.7513C47.7407 55.5647 47.6308 55.3115 47.6308 55.0475C47.6353 52.8543 48.2637 50.7034 49.4491 48.8247C50.7725 46.8035 52.5035 45.6943 54.3535 45.6943C56.2036 45.6943 58.006 46.8287 59.3638 48.8019C60.6208 50.6653 61.2973 52.8312 61.3145 55.0475C61.3148 55.1783 61.2881 55.3079 61.2358 55.4288C61.1835 55.5497 61.1067 55.6596 61.0098 55.7521C60.9129 55.8446 60.7978 55.9179 60.6711 55.9678C60.5445 56.0177 60.4087 56.0432 60.2717 56.0429Z"
                    fill="white"/>
                <path
                    d="M87.0779 56.0429C86.8013 56.0429 86.5361 55.938 86.3405 55.7513C86.1449 55.5647 86.0351 55.3115 86.0351 55.0475C86.0351 51.4017 83.5763 47.6877 81.1624 47.6877C78.8227 47.6877 76.5253 51.3335 76.5253 55.0475C76.5118 55.3031 76.3959 55.5441 76.2016 55.7205C76.0073 55.8969 75.7494 55.9952 75.4812 55.9952C75.213 55.9952 74.9551 55.8969 74.7608 55.7205C74.5664 55.5441 74.4505 55.3031 74.437 55.0475C74.4424 52.8541 75.0718 50.7032 76.258 48.8247C77.5814 46.8035 79.3097 45.6943 81.1624 45.6943C83.0151 45.6943 84.8123 46.8287 86.1701 48.8019C87.429 50.6646 88.1064 52.8308 88.1234 55.0475C88.1234 55.1784 88.0963 55.308 88.0437 55.429C87.9912 55.5499 87.9141 55.6598 87.817 55.7522C87.7199 55.8447 87.6046 55.918 87.4778 55.9679C87.351 56.0177 87.2151 56.0432 87.0779 56.0429Z"
                    fill="white"/>
                <path
                    d="M67.4258 45.8182H67.5185C67.6575 45.8182 67.7952 45.8444 67.9236 45.8952C68.0521 45.946 68.1688 46.0204 68.2671 46.1142C68.3654 46.2081 68.4434 46.3195 68.4966 46.4421C68.5498 46.5647 68.5772 46.6961 68.5772 46.8288V55.1916C68.5772 55.4596 68.4656 55.7167 68.2671 55.9062C68.0685 56.0957 67.7992 56.2022 67.5185 56.2022H67.4258C67.145 56.2022 66.8758 56.0957 66.6772 55.9062C66.4787 55.7167 66.3671 55.4596 66.3671 55.1916V46.8288C66.3671 46.5608 66.4787 46.3038 66.6772 46.1142C66.8758 45.9247 67.145 45.8182 67.4258 45.8182Z"
                    fill="white"/>
                <path
                    d="M60.2717 56.0429C60.1346 56.0432 59.9988 56.0177 59.8722 55.9678C59.7455 55.9179 59.6304 55.8446 59.5335 55.7521C59.4366 55.6596 59.3598 55.5497 59.3075 55.4288C59.2552 55.3079 59.2285 55.1783 59.2288 55.0475C59.2288 51.4017 56.7674 47.6877 54.3535 47.6877C52.0138 47.6877 49.7164 51.3335 49.7164 55.0475C49.7164 55.3115 49.6066 55.5647 49.411 55.7513C49.2154 55.938 48.9502 56.0429 48.6736 56.0429C48.397 56.0429 48.1318 55.938 47.9362 55.7513C47.7407 55.5647 47.6308 55.3115 47.6308 55.0475C47.6353 52.8543 48.2637 50.7034 49.4491 48.8247C50.7725 46.8035 52.5035 45.6943 54.3535 45.6943C56.2036 45.6943 58.006 46.8287 59.3638 48.8019C60.6208 50.6653 61.2973 52.8312 61.3145 55.0475C61.3148 55.1783 61.2881 55.3079 61.2358 55.4288C61.1835 55.5497 61.1067 55.6596 61.0098 55.7521C60.9129 55.8446 60.7978 55.9179 60.6711 55.9678C60.5445 56.0177 60.4087 56.0432 60.2717 56.0429Z"
                    fill="white"/>
                <path
                    d="M87.0779 56.0429C86.8013 56.0429 86.5361 55.938 86.3405 55.7513C86.1449 55.5647 86.0351 55.3115 86.0351 55.0475C86.0351 51.4017 83.5763 47.6877 81.1624 47.6877C78.8227 47.6877 76.5253 51.3335 76.5253 55.0475C76.5118 55.3031 76.3959 55.5441 76.2016 55.7205C76.0073 55.8969 75.7494 55.9952 75.4812 55.9952C75.213 55.9952 74.9551 55.8969 74.7608 55.7205C74.5664 55.5441 74.4505 55.3031 74.437 55.0475C74.4424 52.8541 75.0718 50.7032 76.258 48.8247C77.5814 46.8035 79.3097 45.6943 81.1624 45.6943C83.0151 45.6943 84.8123 46.8287 86.1701 48.8019C87.429 50.6646 88.1064 52.8308 88.1234 55.0475C88.1234 55.1784 88.0963 55.308 88.0437 55.429C87.9912 55.5499 87.9141 55.6598 87.817 55.7522C87.7199 55.8447 87.6046 55.918 87.4778 55.9679C87.351 56.0177 87.2151 56.0432 87.0779 56.0429Z"
                    fill="#181613"/>
                <path
                    d="M41.2654 46.8516V55.0627C41.2649 55.3401 41.1498 55.6061 40.9451 55.803C40.8433 55.9002 40.7224 55.9772 40.5893 56.0297C40.4562 56.0821 40.3136 56.109 40.1696 56.1087C40.0259 56.1087 39.8835 56.0816 39.7507 56.029C39.6179 55.9764 39.4973 55.8993 39.3958 55.8022C39.2942 55.705 39.2137 55.5897 39.159 55.4628C39.1042 55.3359 39.0762 55.1999 39.0765 55.0627L39.1453 50.7828C39.1458 50.7264 39.1252 50.6716 39.0873 50.6284C39.0493 50.5851 38.9964 50.5562 38.9381 50.5467C38.8798 50.5373 38.8199 50.548 38.7691 50.5769C38.7183 50.6058 38.68 50.651 38.661 50.7045L34.6909 55.4038C34.3812 55.7449 34.1271 56.096 33.6666 56.0986C33.206 56.1011 32.809 55.6691 32.6291 55.4215L28.5531 50.6792C28.534 50.6253 28.4952 50.5797 28.4438 50.5508C28.3925 50.5218 28.3319 50.5115 28.2732 50.5216C28.2145 50.5317 28.1616 50.5616 28.1241 50.6058C28.0865 50.6501 28.0669 50.7057 28.0687 50.7626L27.9946 55.0577C27.9946 55.1949 27.9662 55.3308 27.9111 55.4575C27.856 55.5843 27.7753 55.6994 27.6735 55.7964C27.5717 55.8933 27.4509 55.9701 27.318 56.0224C27.1851 56.0747 27.0426 56.1014 26.8989 56.1011C26.6103 56.1045 26.332 55.9988 26.1248 55.8071C25.9175 55.6154 25.7981 55.3533 25.7925 55.0779V46.8516C25.7932 46.5748 25.9085 46.3095 26.1133 46.1136C26.3181 45.9176 26.5957 45.8069 26.8856 45.8056C27.3435 45.8056 27.5897 46.0785 27.9126 46.4903L33.2669 52.9404C33.349 53.0314 33.3993 53.0971 33.5025 53.0971C33.6057 53.0971 33.6639 53.0238 33.7354 52.9379L39.1268 46.5054C39.4153 46.1593 39.6959 45.8056 40.159 45.8056C40.3038 45.8043 40.4475 45.8303 40.5816 45.8823C40.7158 45.9343 40.8378 46.0111 40.9407 46.1084C41.0436 46.2056 41.1252 46.3214 41.181 46.4489C41.2367 46.5765 41.2654 46.7134 41.2654 46.8516Z"
                    fill="#181613"/>
                <path
                    d="M67.4258 45.8182H67.5185C67.6575 45.8182 67.7952 45.8444 67.9236 45.8952C68.0521 45.946 68.1688 46.0204 68.2671 46.1142C68.3654 46.2081 68.4434 46.3195 68.4966 46.4421C68.5498 46.5647 68.5772 46.6961 68.5772 46.8288V55.1916C68.5772 55.4596 68.4656 55.7167 68.2671 55.9062C68.0685 56.0957 67.7992 56.2022 67.5185 56.2022H67.4258C67.145 56.2022 66.8758 56.0957 66.6772 55.9062C66.4787 55.7167 66.3671 55.4596 66.3671 55.1916V46.8288C66.3671 46.5608 66.4787 46.3038 66.6772 46.1142C66.8758 45.9247 67.145 45.8182 67.4258 45.8182Z"
                    fill="white"/>
                <path
                    d="M60.9572 53.3826V53.471C60.9572 53.739 60.8456 53.9961 60.6471 54.1856C60.4485 54.3751 60.1792 54.4816 59.8985 54.4816H54.2583C53.9775 54.4816 53.7082 54.3751 53.5096 54.1856C53.3111 53.9961 53.1996 53.739 53.1996 53.471V53.3826C53.1996 53.1145 53.3111 52.8575 53.5096 52.6679C53.7082 52.4784 53.9775 52.3719 54.2583 52.3719H59.9064C60.1858 52.3739 60.4531 52.4813 60.6499 52.6706C60.8467 52.8599 60.9572 53.1158 60.9572 53.3826Z"
                    fill="white"/>
                <path
                    d="M87.5675 53.3826V53.471C87.5675 53.739 87.456 53.9961 87.2575 54.1856C87.0589 54.3751 86.7896 54.4816 86.5088 54.4816H80.8686C80.5879 54.4816 80.3186 54.3751 80.12 54.1856C79.9215 53.9961 79.8099 53.739 79.8099 53.471V53.3826C79.8099 53.1145 79.9215 52.8575 80.12 52.6679C80.3186 52.4784 80.5879 52.3719 80.8686 52.3719H86.5168C86.7962 52.3739 87.0634 52.4813 87.2603 52.6706C87.4571 52.8599 87.5676 53.1158 87.5675 53.3826Z"
                    fill="#181613"/>
                <path
                    d="M60.2717 56.0429C60.1346 56.0432 59.9989 56.0177 59.8722 55.9678C59.7455 55.9179 59.6304 55.8446 59.5335 55.7521C59.4366 55.6596 59.3598 55.5497 59.3075 55.4288C59.2552 55.3079 59.2285 55.1783 59.2289 55.0475C59.2289 51.4017 56.7674 47.6877 54.3536 47.6877C52.0138 47.6877 49.7165 51.3335 49.7165 55.0475C49.703 55.3031 49.5871 55.5441 49.3927 55.7205C49.1984 55.8969 48.9405 55.9952 48.6723 55.9952C48.4041 55.9952 48.1462 55.8969 47.9519 55.7205C47.7576 55.5441 47.6417 55.3031 47.6282 55.0475C47.6336 52.8541 48.263 50.7032 49.4491 48.8247C50.7725 46.8035 52.5035 45.6943 54.3536 45.6943C56.2036 45.6943 58.0061 46.8287 59.3638 48.8019C60.6227 50.6646 61.3002 52.8308 61.3171 55.0475C61.3171 55.1784 61.2901 55.308 61.2375 55.429C61.1849 55.5499 61.1079 55.6598 61.0108 55.7522C60.9137 55.8447 60.7984 55.918 60.6716 55.9679C60.5447 56.0177 60.4088 56.0432 60.2717 56.0429Z"
                    fill="white"/>
            </g>
            <defs>
                <linearGradient id="paint0_linear_44_95" x1="66.14" y1="10.6069" x2="66.14" y2="96.4163"
                                gradientUnits="userSpaceOnUse">
                    <stop offset="0.015" stopColor="#7A2180"/>
                    <stop offset="0.625" stopColor="#BC0E80"/>
                    <stop offset="0.96" stopColor="#E40280"/>
                </linearGradient>
                <linearGradient id="paint1_linear_44_95" x1="41.2552" y1="0.000732422" x2="41.2552" y2="107.607"
                                gradientUnits="userSpaceOnUse">
                    <stop offset="0.015" stopColor="#7A2180"/>
                    <stop offset="0.625" stopColor="#BC0E80"/>
                    <stop offset="0.96" stopColor="#E40280"/>
                </linearGradient>
                <linearGradient id="paint2_linear_44_95" x1="56.8936" y1="14.9342" x2="56.8936" y2="111.999"
                                gradientUnits="userSpaceOnUse">
                    <stop offset="0.015" stopColor="#7A2180"/>
                    <stop offset="0.625" stopColor="#BC0E80"/>
                    <stop offset="0.96" stopColor="#E40280"/>
                </linearGradient>
                <clipPath id="clip0_44_95">
                    <rect width="124" height="112" fill="white"/>
                </clipPath>
            </defs>
        </svg>


    );
}

export default KnowledgeBaseIcon;
