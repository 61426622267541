//
//
//  Projects
//
//

import {Button, Dialog, DialogPanel} from "@tremor/react";
import mainProjectsData from "./data/main.tsx";
import Project from "./components/Project.tsx";
import {useState} from "react";
import {useAuth0} from "@auth0/auth0-react";
import MaiaStudioIcon from "./assets/MaiaStudioIcon.tsx";
import DebtCollectionIcon from "./assets/DebtCollectionIcon.tsx";
import SummaryIcon from "./assets/SummaryIcon.tsx";
import KnowledgeBaseIcon from "./assets/KnowledgeBaseIcon.tsx";
import LeadProspectorIcon from "./assets/LeadProspector.tsx";
import TrainerIcon from "./assets/TrainerIcon.tsx";
import AssistantIcon from "./assets/AssistantIcon.tsx";
import LogoIcon from "./components/LogoIcon.tsx";
import { useNavigate } from "react-router-dom";
import MaiaCognitiveIcon from "./assets/MaiaCognitiveIcon.tsx";
import {RiLogoutBoxRLine} from "@remixicon/react";

function Projects() {
    const { logout } = useAuth0();
    const navigate = useNavigate();
    const [projectIndexOpened, setProjectIndexOpened] = useState<number>(-1)

    return (
        <>
            <div className="container mx-auto snap-center mb-10 min-w-screen">
                <div className="flex justify-between mb-12 mt-14">
                    <h1>
                        <MaiaCognitiveIcon/>
                    </h1>
                    <div>
                        <Button size="xs" variant="secondary" icon={RiLogoutBoxRLine} iconPosition="right" onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>Logout</Button>
                    </div>
                </div>
                <div className="flex justify-center items-center">
                    <div className="rounded-full relative shadow-md min-w-[35rem] min-h-[35rem]">
                        <div className="absolute top-0 left-0 translate-x-1/2 p-2 flex">
                            <div className="relative">
                                <div className="absolute top-0 left-[-14rem] w-[16rem] text-end">
                                    <h2><b>Trainer</b></h2>
                                    <div>Conversational AI facilitating personalized training experiences</div>
                                </div>
                                <button onClick={() => setProjectIndexOpened(3)} className="absolute top-0 left-0  translate-x-1/2 p-2 rounded-full">
                                    <LogoIcon>
                                        <TrainerIcon/>
                                    </LogoIcon>
                                </button>
                            </div>
                        </div>
                        <div className="absolute top-0 right-0 -translate-x-1/2">
                            <div className="relative">
                                <div className="absolute top-0 right-[-14rem] w-[16rem] text-start">
                                    <h2><b>Assistant</b></h2>
                                    <div>Revolutionizing CX with simulated interactions and automation.</div>
                                </div>
                                <button onClick={() => setProjectIndexOpened(0)} className="absolute top-0 right-0 -translate-x-1/2 p-2">
                                    <LogoIcon>
                                        <AssistantIcon/>
                                    </LogoIcon>
                                </button>
                            </div>
                        </div>
                        <div className="absolute rounded-full bottom-0 right-0 transform -translate-x-1/2">
                            <div className="relative">
                                <div className="absolute bottom-0 right-[-14rem] w-[16rem] text-start">
                                    <h2><b>Lead Prospector</b></h2>
                                    <div>Improving sales and marketing ops with AI</div>
                                </div>
                                <button onClick={() => setProjectIndexOpened(6)} className="absolute bottom-0 right-0 -translate-x-1/2 p-2">
                                    <LogoIcon>
                                        <LeadProspectorIcon/>
                                    </LogoIcon>
                                </button>
                            </div>
                        </div>
                        <div className="absolute rounded-full bottom-0 left-0 translate-x-1/2">
                            <div className="relative">
                                <div className="absolute bottom-0 left-[-14rem] w-[16rem] text-end">
                                    <h2><b>Knowledge Base</b></h2>
                                    <div>Transforming information retrieval in dynamic conversations.</div>
                                </div>
                                <button onClick={() => setProjectIndexOpened(5)} className="absolute bottom-0 left-0 translate-x-1/2 p-2">
                                    <LogoIcon>
                                        <KnowledgeBaseIcon/>
                                    </LogoIcon>
                                </button>
                            </div>
                        </div>
                        <div className="absolute top-1/2 left-0 -translate-x-1/2 -translate-y-1/2">
                            <div className="relative">
                                <div className="absolute top-1/2 left-[-14rem] -translate-x-1/2 -translate-y-1/2 w-[16rem] text-end">
                                    <h2><b>Summary</b></h2>
                                    <div>Let MAIA summarize high conversation volumes.</div>
                                </div>
                                <button onClick={() => setProjectIndexOpened(4)} className="absolute top-0 left-0 -translate-x-1/2 -translate-y-1/2 p-2">
                                    <LogoIcon>
                                        <SummaryIcon/>
                                    </LogoIcon>
                                </button>
                            </div>
                        </div>
                        <div className="absolute border-round top-1/2 right-0  translate-x-1/2 -translate-y-1/2">
                            <div className="relative">
                                <div className="absolute top-1/2 left-[14rem] -translate-x-1/2 -translate-y-1/2 w-[16rem] text-start">
                                    <h2><b>Debt Collection</b></h2>
                                    <div>Simplify recovery processes, while gaining control and transparency.</div>
                                </div>
                                <button onClick={() => setProjectIndexOpened(2)} className="absolute top-1/2 left-0 -translate-x-1/2 -translate-y-1/2 p-2">
                                    <LogoIcon>
                                        <DebtCollectionIcon/>
                                    </LogoIcon>
                                </button>
                            </div>
                        </div>
                        <button onClick={() => setProjectIndexOpened(1)} className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white text-gray-800 p-2">
                            <LogoIcon>
                                    <MaiaStudioIcon/>
                            </LogoIcon>
                            <div className="text-2xl font-bold">Studio</div>
                        </button>
                    </div>
                </div>
                <div className="flex justify-end mb-12 mt-14">
                    <div className="flex">
                        <Button size="xs" variant="primary" onClick={()=> navigate('other')}>Find out more...</Button>
                    </div>
                </div>
            </div>
            <Dialog open={projectIndexOpened >= 0} onClose={() => setProjectIndexOpened(-1)} static={true}>
                {projectIndexOpened >= 0 && <DialogPanel className="max-w-fit">
                    <Project project={mainProjectsData[projectIndexOpened]}/>
                </DialogPanel>}
            </Dialog>
        </>
    )
}

export default Projects