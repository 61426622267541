//
//
//  Data 
//
//

import maiaTelcoImg from "../assets/maia-telco.png";
import maiaTrainerImg from "../assets/maia-trainer.png";
import maiaStudioImg from "../assets/maia-studio.png";
import maiaDebtCollectionImg from "../assets/maia-debt-collection.png";
import maiaSummaryImg from "../assets/maia-summary.png";


export default [
    {
        title: "MAIA Assistant",
        description: "MAIA Telco is a chatbot that allows you to manage your telco account",
        image: maiaTelcoImg,
        dataSections: [
            {
                "section": "Production",
                "items": [
                    {
                        "name": "WhatsApp",
                        "value": "+34 645029070"
                    },
                    {
                        "name": "Telegram",
                        "value": <a href="https://t.me/maia_telco_bot" className="text-blue-600" target="_blank">maia_telco_bot</a>
                    },
                    {
                        "name": "Phone",
                        "value": "+34 900759335"
                    },
                    {
                        "name": "ChatWeb",
                        "value": "https://conversation-manager-s5aykko6eq-no.a.run.app/chatweb/1"
                    },
                    {
                        "name": "Conversation Manager",
                        "value": "https://conversation-manager-s5aykko6eq-no.a.run.app"
                    }
                ]
            },
            {
                "section": "Pre-Production",
                "items": [
                    {
                        "name": "Telegram",
                        "value": <a href="https://t.me/maia_telco_pre_bot" className="text-blue-600" target="_blank">maia_telco_pre_bot</a>
                    },
                    {
                        "name": "Phone",
                        "value": "+34 511604879"
                    },
                    {
                        "name": "ChatWeb",
                        "value": "https://conversation-manager-kgub34fcuq-no.a.run.app/chatweb/1"
                    },
                    {
                        "name": "Conversation Manager",
                        "value": "https://conversation-manager-kgub34fcuq-no.a.run.app"
                    }
                ],
            },
            {
                "section": "",
                "items": [
                    {
                        "name": "Repository",
                        "value": "https://github.com/MAJOREL-IBILAT/maia-telco-rasa"
                    },
                    {
                        "name": "Repository (Web)",
                        "value": "https://github.com/MAJOREL-IBILAT/maia-telco-web"
                    }
                ]
            }
        ],
    },
    {
        title: "MAIA Studio",
        description: "MAIA Studio is a platform that allows you to create and manage your own chatbots.",
        image: maiaStudioImg,
        dataSections: [
            {
                "section": "Production",
                "items": [
                    {
                        "name": "Website",
                        "value": <a className="text-blue-600" href="https://studio.maia-cognitive.com">studio.maia-cognitive.com</a>,
                    }
                ]
            },
            {
                "section": "Pre-Production",
                "items": [
                    {
                        "name": "Website",
                        "value": "https://staging.studio.maia-cognitive.com",
                    },
                ]
            },
            {
                "section": "",
                "items": [
                    {
                        "name": "Repository",
                        "value": "https://github.com/MAJOREL-IBILAT/maia-studio"
                    },
                    {
                        "name": "Repository (Conversation Manager)",
                        "value": "https://github.com/MAJOREL-IBILAT/maia-trainer-conversation-manager",
                    },
                    {
                        "name": "Conversation Manager",
                        "value": "https://conversation-manager-c4ow2pmota-no.a.run.app"
                    }
                ]
            }
        ]
    },
    {
        title: "MAIA Debt Collection",
        description: "MAIA Debt Collection is a chatbot that allows you to manage your debt collection",
        image: maiaDebtCollectionImg,
        dataSections: [
            {
                section: "Pre-Production",
                items: [
                    {
                        "name": "Website",
                        "value": "https://staging.debt-collection.maia-cognitive.com"
                    },
                ]
            },
            {
                section: "Production",
                items: [
                    {
                        "name": "Website",
                        "value": "https://debt-collection.maia-cognitive.com"
                    },
                ]
            },
            {
                section: "",
                items: [
                    {
                        "name": "Repository (Rasa)",
                        "value": "https://github.com/MAJOREL-IBILAT/debt_collection_rasa"
                    },
                    {
                        "name": "Repository (Web)",
                        "value": "https://github.com/MAJOREL-IBILAT/debt_collection"
                    },
                    {
                        "name": "Repository (Conversation Manager)",
                        "value": "https://github.com/MAJOREL-IBILAT/maia-debt-collection-conversation-manager"
                    }
                ]
            }
        ]
    },
    {
        title: "MAIA Trainer",
        description: "MAIA Trainer is a platform that allows you to train people by using chatbots",
        image: maiaTrainerImg,
        dataSections: [
            {
                section: "Production",
                items: [
                    {
                        name: "Website",
                        value: "https://trainer.maia-cognitive.com"
                    },
                    {
                        "name": "Conversation Manager",
                        "value": "https://conversation-manager-snvt53fvia-no.a.run.app"
                    },
                    {
                        "name": "Telegram",
                        "value": <a href="https://t.me/maia_trainer_bot" className="text-blue-600" target="_blank">maia_trainer_bot</a>
                    },
                    {
                        "name": "Teléfono",
                        "value": "+34 900759337"
                    },
                    {
                        "name": "ChatWeb",
                        "value": "https://conversation-manager-snvt53fvia-no.a.run.app/chatweb/2"
                    },
                ]
            },
            {
                section: "Pre-Production",
                items: [
                    {
                        "name": "Website",
                        "value": "https://staging.trainer.maia-cognitive.com"
                    },
                    {
                        "name": "Conversation Manager",
                        "value": "https://conversation-manager-h3dfk66e7a-no.a.run.app"
                    },
                    {
                        "name": "Telegram",
                        "value": <a href="https://t.me/maia_trainer_pre_bot" className="text-blue-600" target="_blank">maia_trainer_pre_bot</a>
                    },
                    {
                        "name": "ChatWeb",
                        "value": "https://conversation-manager-h3dfk66e7a-no.a.run.app/chatweb/2"
                    },
                ]
            },
            {
                section: "Repositories",
                items: [
                    {
                        "name": "App",
                        "value": "https://github.com/MAJOREL-IBILAT/maia-trainer"
                    },
                    {
                        "name": "Conversation Manager",
                        "value": "https://github.com/MAJOREL-IBILAT/maia-trainer-conversation-manager"
                    }
                ]
            }
        ]
    },
    {
        title: "MAIA Summary",
        description: "MAIA Summary summarizes text and audio files",
        image: maiaSummaryImg,
        dataSections: [
            {
                section: "Pre-Production",
                items: [
                    {
                        "name": "Website",
                        "value": "https://staging.summary.maia-cognitive.com"
                    },
                ]
            },
            {
                section: "Production",
                items: [
                    {
                        "name": "Website",
                        "value": "https://summary.maia-cognitive.com"
                    },
                ]
            },
            {
                "section": "",
                "items": [
                    {
                        "name": "Repository",
                        "value": "https://github.com/MAJOREL-IBILAT/maia-summary",
                    }
                ]
            }
        ]
    },
    {
        title: "MAIA Knowledge Base",
        description: "MAIA Knowledge Base is a platform that allows you to talk with your own data",
        dataSections: [
            {
                "section": "",
                "items": [
                    {
                        "name": "Website",
                        "value": "https://knowledge-base.maia-cognitive.com"
                    },
                ]
            },
            {
                "section": "Pre-Production",
                "items": [
                    {
                        "name": "Website",
                        "value": "https://staging.knowledge-base.maia-cognitive.com"
                    },
                ]
            },
            {
                "section": "Development",
                "items": [
                    {
                        "name": "Repository",
                        "value": "https://github.com/MAJOREL-IBILAT/maia-knowledge-base"
                    }
                ]
            }
        ]
    },
    {
        title: "MAIA Lead Prospector",
        description: "Coming soon!",
        dataSections: []
    },
]
